<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout-header class="layhead">
        <top-bar msg="" showback="0" />
      </a-layout-header>
      <a-layout>
        <sider-bar />
        <a-layout-content class="adminalcontent">
          <div class="maindiv">
            <a-breadcrumb class="bread">
              <a-breadcrumb-item>
                <router-link to="/index"> 控制台 </router-link>
              </a-breadcrumb-item>
              <a-breadcrumb-item> 系统设置 </a-breadcrumb-item>
            </a-breadcrumb>

            <div
              style="height: calc(100%-100px); width: 100%; overflow-x: auto"
            >
              <a-form :form="form" class="myform" @submit="handleSubmit">
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="首页PC顶图"
                >
                  <a-upload
                    action=""
                    list-type="picture-card"
                    :show-upload-list="false"
                    :before-upload="
                      (file) => {
                        beforeImgUpload(file, 0);
                      }
                    "
                  >
                    <img
                      v-if="tback0url"
                      class="tmimage"
                      :src="baseUrl + tback0url"
                      alt="avatar"
                      style="height: 90px"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>

                  <a-input
                    hidden
                    v-decorator="[
                      'tback0url',
                      { rules: [{ required: false }] },
                    ]"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="首页移动端顶图"
                >
                  <a-upload
                    action=""
                    list-type="picture-card"
                    :show-upload-list="false"
                    :before-upload="
                      (file) => {
                        beforeImgUpload(file, 1);
                      }
                    "
                  >
                    <img
                      v-if="tback0murl"
                      class="tmimage"
                      :src="baseUrl + tback0murl"
                      alt="avatar"
                      style="height: 90px"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>

                  <a-input
                    hidden
                    v-decorator="[
                      'tback0murl',
                      { rules: [{ required: false }] },
                    ]"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="AI页PC顶图"
                >
                  <a-upload
                    action=""
                    list-type="picture-card"
                    :show-upload-list="false"
                    :before-upload="
                      (file) => {
                        beforeImgUpload(file, 2);
                      }
                    "
                  >
                    <img
                      v-if="tback1url"
                      class="tmimage"
                      :src="baseUrl + tback1url"
                      alt="avatar"
                      style="height: 90px"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>

                  <a-input
                    hidden
                    v-decorator="[
                      'tback1url',
                      { rules: [{ required: false }] },
                    ]"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="AI页移动端顶图"
                >
                  <a-upload
                    action=""
                    list-type="picture-card"
                    :show-upload-list="false"
                    :before-upload="
                      (file) => {
                        beforeImgUpload(file, 3);
                      }
                    "
                  >
                    <img
                      v-if="tback1murl"
                      class="tmimage"
                      :src="baseUrl + tback1murl"
                      alt="avatar"
                      style="height: 90px"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传图片</div>
                    </div>
                  </a-upload>
                  <a-input
                    hidden
                    v-decorator="[
                      'tback1murl',
                      { rules: [{ required: false }] },
                    ]"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="显示首页视频"
                >
                  <a-switch v-decorator="['video0show']" v-model="video0show">
                    <a-icon slot="checkedChildren" type="check" />
                    <a-icon slot="unCheckedChildren" type="close" />
                  </a-switch>
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="视频"
                  v-if="video0show"
                >
                  <a-upload
                    :multiple="true"
                    :action="`${baseUrl}/upload_file.php`"
                    :headers="headers"
                    @change="handleVideoChange"
                    list-type="picture-card"
                    :show-upload-list="false"
                    :before-upload="beforeVideoUpload"
                  >
                    <video
                      v-if="video0url"
                      class="tmimage"
                      :src="baseUrl + video0url"
                      alt="avatar"
                      style="height: 90px"
                    />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">上传视频</div>
                    </div>
                  </a-upload>
                  <a-input
                    hidden
                    v-decorator="[
                      'video0url',
                      { rules: [{ required: false }] },
                    ]"
                    placeholder=""
                  />
                </a-form-item>

                <h3 style="padding-left: 30px; color: #000">导航栏</h3>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="名称"
                >
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t0']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t1']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t2']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t3']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t4']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t45']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t5']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <br />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t6']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t7']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t8']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t9']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t10']"
                    :maxLength="200"
                    placeholder=""
                  />
                  <a-input
                    style="width: 10%; margin-right: 10px"
                    v-decorator="['t11']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>

                <h3 style="padding-left: 30px; color: #000">基础信息</h3>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="地址"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['adr']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="联系电话"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['tel']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="EMAIL"
                  style="margin-bottom: 50px"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['email']"
                    :maxLength="200"
                    placeholder="多个关键词请用“,”分开"
                  />
                </a-form-item>

                <h3 style="padding-left: 30px; color: #000">关于我们</h3>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="标题"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['mtitle']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="简介"
                  style="margin-bottom: 50px"
                >
                  <input
                    type="hidden"
                    v-decorator="['mcontent']"
                    placeholder=""
                  />
                  <my-editor
                    @HtmlonChange="HtmlonChange"
                    :content="mcontent"
                  ></my-editor>
                </a-form-item>

                <h3 style="padding-left: 30px; color: #000">AI简介</h3>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="标题"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['aititle']"
                    :maxLength="200"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="简介"
                >
                  <input
                    type="hidden"
                    v-decorator="['aicontent']"
                    placeholder=""
                  />
                  <my-editor
                    @HtmlonChange="HtmlonChangeAi"
                    :content="aicontent"
                  ></my-editor>
                </a-form-item>

                <h3 style="padding-left: 30px; color: #000">AI平台内容</h3>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="会务系统标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['aititle0']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="会务系统"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['aicontent0']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="数字分析标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['aititle1']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="数字分析"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['aicontent1']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="智慧云展标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['aititle2']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="智慧云展"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['aicontent2']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="数字孪生标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['aititle3']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="数字孪生"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['aicontent3']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="AIGC标题"
                >
                  <a-input
                    style="width: 30%"
                    v-decorator="['aititle4']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>
                <a-form-item
                  :label-col="formItemLayout.labelCol"
                  :wrapper-col="formItemLayout.wrapperCol"
                  label="AIGC"
                >
                  <a-input
                    style="width: 100%"
                    v-decorator="['aicontent4']"
                    :maxLength="500"
                    placeholder=""
                  />
                </a-form-item>

                <a-form-item style="padding-left: 110px; padding-bottom: 100px" v-if="thisrule==3">
                  <a-button type="primary" html-type="submit"> 修改 </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
          <a-layout-footer class="layfoot">
            <footer-bar />
          </a-layout-footer>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
const formItemLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const formTailLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 10, offset: 6 },
};

import { fetchClass, fetchBase, updateBase, uploadImage } from "../api/index";
import global from "../common";
import MyEditor from "../components/MyEditor.vue";
import TopBar from "../components/TopBar.vue";
import SiderBar from "../components/Sider.vue";
import FooterBar from "../components/FooterBar.vue";
export default {
  name: "myuserinfo",
  components: {
    TopBar,
    FooterBar,
    SiderBar,
    MyEditor,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      thisrule:0,
      formItemLayout,
      formTailLayout,
      tsmodelvisible: true,
      maskClosable: false,
      userinfo: [],
      loading: false,
      visiblePasswordModal: false,
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),

      fileList: [],

      mcontent: "",
      aicontent: "",
      tback0url: null,
      tback0murl: null,
      tback1url: null,
      tback1murl: null,
      video0url: null,
      video0show: false,

      baseUrl: "",
    };
  },
  mounted() {
    let that = this;
    this.baseUrl = global.baseUrl;
    that.fetch();
    this.thisrule = global.checkrule();
  },
  methods: {
    handleVideoChange(info) {
      console.log(info.file);

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.video0url = info.file.response.url;
        this.form.setFieldsValue({
          video0url: info.file.response.url,
        });

        // let videoary = this.videoary;

        // videoary.push({
        //   surl: global.baseUrl + info.file.response.url,
        //   url: info.file.response.url,
        //   title: "",
        //   lock: "0",
        // });

        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },

    beforeVideoUpload(file) {
      const isJpgOrPng = file.type === "video/mp4";
      if (!isJpgOrPng) {
        this.$message.error("请上传MP4视频!");
      }
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error("视频要小于 100MB!");
      }
      return isJpgOrPng && isLt2M;
    },

    async beforeImgUpload(file, type) {
      const that = this;
      const { form } = this;
      that.uploading = true;
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        that.$message.error("你只能上传jpeg或者png文件!");
      }
      console.log("file", file);
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        that.$message.error("图片必须要小于 10MB!");
        that.uploading = false;
        return false;
      }

      let imageUrl = await this.getBase64(file);
      // getBase64(file, (imageUrl) => {
      that.imageUrl = "";

      that.uploading = false;
      console.log("this.imageUrl:", imageUrl);
      // can use data-binding to set

      uploadImage({ img: imageUrl, filename: file.name, kind: "info" }).then(
        (res) => {
          console.log(res);
          let results = res.results;
          if (results.message == "1") {
            that.$message.success("上传成功");
            that.imgurl = global.baseUrl + results.url;
            setTimeout(() => {
              if (type == 0) {
                that.tback0url = global.baseUrl + results.url;
                form.setFieldsValue({
                  tback0url: results.url,
                });
              } else if (type == 1) {
                that.tback0murl = global.baseUrl + results.url;
                form.setFieldsValue({
                  tback0murl: results.url,
                });
              } else if (type == 2) {
                that.tback1url = global.baseUrl + results.url;
                form.setFieldsValue({
                  tback1url: results.url,
                });
              } else if (type == 3) {
                that.tback1murl = global.baseUrl + results.url;
                form.setFieldsValue({
                  tback1murl: results.url,
                });
              } else if (type == 4) {
                that.video0url = global.baseUrl + results.url;
                form.setFieldsValue({
                  video0url: results.url,
                });
              }
            });
            // that.imgary = [
            //   {
            //     url: results.url,
            //   },
            // ];
          } else if (results.message == "0") {
            that.$message.wrong("上传失败");
          }
        }
      );
      // });
      return isJpgOrPng && isLt2M;
    },

    HtmlonChange(html) {
      this.form.setFieldsValue({
        mcontent: html,
      });
    },
    HtmlonChangeAi(html) {
      this.form.setFieldsValue({
        aicontent: html,
      });
    },

    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";
      var uuid = s.join("");
      return uuid;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    fetch(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        console.log("fetchBase:", res.results);
        that.loading = false;
        this.data = res.results;

        // let fileList = [];

        // this.data.imgary.map((item) => {
        //   let uid = that.uuid();
        // });

        // this.fileList = fileList;

        // console.log("fileList:", fileList);

        this.mcontent = this.data.mcontent;
        this.aicontent = this.data.aicontent;
        
        this.tback0url = this.data.tback0;
        this.tback0murl = this.data.tback0m;
        this.tback1url = this.data.tback1;
        this.tback1murl = this.data.tback1m;
        this.video0url = this.data.video0;

        if (this.data.video0show == "true") {
          this.video0show = true;
        } else {
          this.video0show = false;
        }

        this.form.setFieldsValue({
          t0: this.data.t0,
          t1: this.data.t1,
          t2: this.data.t2,
          t3: this.data.t3,
          t4: this.data.t4,
          t45: this.data.t45,
          t5: this.data.t5,
          t6: this.data.t6,
          t7: this.data.t7,
          t8: this.data.t8,
          t9: this.data.t9,
          t10: this.data.t10,
          t11: this.data.t11,

          tel: this.data.tel,
          adr: this.data.adr,
          email: this.data.email,
          mtitle: this.data.mtitle,
          mcontent: this.data.mcontent,
          aititle: this.data.aititle,
          aicontent: this.data.aicontent,
          aicontent0: this.data.aicontent0,
          aicontent1: this.data.aicontent1,
          aicontent2: this.data.aicontent2,
          aicontent3: this.data.aicontent3,
          aicontent4: this.data.aicontent4,

          aititle0: this.data.aititle0,
          aititle1: this.data.aititle1,
          aititle2: this.data.aititle2,
          aititle3: this.data.aititle3,
          aititle4: this.data.aititle4,

          tback0url: this.data.tback0,
          tback0murl: this.data.tback0m,
          tback1url: this.data.tback1,
          tback1murl: this.data.tback1m,
          video0url: this.data.video0,
          video0show: this.data.video0show,
        });
      });
    },

    handleSubmit() {
      const that = this;
      this.form.validateFields((err, values) => {
        console.log("Received values of form: ", values);

        if (!err) {
          console.info("success");

          let postdata = {
            t0: values.t0,
            t1: values.t1,
            t2: values.t2,
            t3: values.t3,
            t4: values.t4,
            t45: values.t45,
            t5: values.t5,
            t6: values.t6,
            t7: values.t7,
            t8: values.t8,
            t9: values.t9,
            t10: values.t10,
            t11: values.t11,

            adr: values.adr,
            tel: values.tel,
            email: values.email,
            mtitle: values.mtitle,
            mcontent: values.mcontent,
            aititle: values.aititle,
            aicontent: values.aicontent,
            aicontent0: values.aicontent0,
            aicontent1: values.aicontent1,
            aicontent2: values.aicontent2,
            aicontent3: values.aicontent3,
            aicontent4: values.aicontent4,

            aititle0: values.aititle0,
            aititle1: values.aititle1,
            aititle2: values.aititle2,
            aititle3: values.aititle3,
            aititle4: values.aititle4,

            tback0: values.tback0url,
            tback0m: values.tback0murl,
            tback1: values.tback1url,
            tback1m: values.tback1murl,
            video0: values.video0url,
            video0show: that.video0show,
          };

          console.log("postdata:", postdata);

          // alert(this.action);

          // return false;

          updateBase(postdata).then((res) => {
            console.log("updateCourse:", res);
            let results = res.results;
            if (results.message == "1") {
              that.$message.success("修改成功");
              that.visibleAddModal = false;
              that.form.resetFields();
              that.fetch();
            }
            that.confirmLoading = false;
            that.visibleAddModal = false;
          });
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ass {
}
.tabletools {
  padding: 20px;
  background: #fff;
}

.divtools {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.divtools .dtileft {
  width: 130px;
}
.divtools .dtiright {
  width: calc(100% - 140px);
  background-color: #fff;
  height: 410px;
  padding: 20px;
  box-sizing: border-box;
}
.fastlist {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;

  flex-wrap: wrap;
}
.fastlist .fastitem {
  width: 100px;
  background-color: #f6f6f6;
  min-height: 100px;
  margin-bottom: 10px;
  border: 1px solid #eee;

  box-sizing: border-box;

  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: #000;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fastlist .fastitem .aicon {
  font-size: 32px;
  color: #1890ff;
  margin-bottom: 5px;
}

.asstable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.photo img {
  height: 140px;
  width: 120px;
  background-color: #fff;
  padding: 5px;
  /* padding-top: 10px; */
  -o-object-fit: contain;
  object-fit: contain;
}

.userinfo {
  line-height: 32px;
  font-size: 14px;
}
.userinfo label {
  color: #999;
}
</style>
